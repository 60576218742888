.packs__list{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.pack{
    &__item{
        display: block;
        text-decoration: none;
        margin-top: em(30);
        width: 100%;
        text-align: center;
        transition: opacity .3s ease-in-out;
        @include rIn(768,1023){
            padding: 0 em(15);
            width: 50%;
        }
        @include rmin(1024){
            padding: 0 em(15);
            width: 30%;
        }
        &:hover{
            opacity: .85;
            cursor: pointer;
        }
        p{
            color: $color-main;
            margin: em(12) 0 0;
        }

        &-image{
            padding: 0;
            position: absolute;
            width: 100%;
            height: 149%;
            top: -24.5%;

            left: 0;
            &-wrapper{
                overflow: hidden;
                border-radius: 8px;
                @include aspect-ratio(317,132,true);

            }
        }


    }
}
