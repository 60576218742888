.main-page{
    .intro{
        &__lang{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: em(25);
            flex-wrap: wrap;
            @include r(767){
                justify-content: center;
            }
            &-item{
                min-width: 260px;
                margin: 0 em(10);
                @include r(767){
                    margin: 0 auto em(25);
                    min-width: 200px
                }
            }
            .btn__wrap{
                padding: em(5) em(13) em(5) em(5);
            }
            .btn__icon{
                margin-left: 0;
                margin-right: em(12);
            }
        }
    }
}
