// Font
$main-font: 'Zona Pro', 'Arial', sans-serif;


:root{
    --color-main: #FFFFFF;
}

$main-font-size: 18px;
$main-font-weight: 400;
$main-line-height: 1;
$secondary-font: 'Amarillo';

$color-main: #FFFFFF;
$color-red: rgb(138, 15, 6);
$color-gold: #F3E6C5;


// Transitions
$transition-base: 0.33s cubic-bezier(.23, 1, .32, 1);
$transition-slow: .6s cubic-bezier(0.08, 1.00, 0.21, 1.00);
