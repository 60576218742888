
.print{
    &__pack{
        margin: em(30) auto em(25);
        text-align: center;
        position: relative;
        width: fit-content;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
		// .pack__item-image-wrapper{
		// 	width: 100%;
		// }
        &::before{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.99%, #000000 100%);
            opacity: 0.5;
        }
        img{
            border-radius: 8px;
        }
    }
    .link{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: fit-content;
        margin: em(10) auto 0;
        text-decoration: none;
        color: currentColor;
        padding: em(15);
        transition: opacity .3s ease-in-out;
        img{
            margin-right: em(10);
        }
        &:hover{
            opacity: .5;
        }
    }
    &__code{
        .number{
            display: block;
            text-align: center;
            line-height: 1.7;
            font-size: 125%;
        }
    }
}

