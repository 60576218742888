@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";
@import "utils/normalize";
@import '../../node_modules/swiper/swiper-bundle';
// @import "flatpickr/dist/flatpickr.min"; // no need ./node_modules

html,
body {
    height: 100%;
}

html {
    font-size: 62.5%; // 10px = 1rem
    line-height: $main-line-height;
}

body {
    margin: 0;
    padding: 0;
    font-family: $main-font;
    @include css-lock(18, 24, 1366, 1920);
    line-height: $main-line-height;
    font-weight: $main-font-weight;
    color: $color-main;
    @extend %bg-radial-red;
    @extend %antialias;
    width: 100%;
    position: relative;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}

img,
svg {
    max-width: 100%;
}

svg {
    fill: currentColor;
    max-height: 100%;
}

button,
a {
    cursor: pointer;
}

strong,
.bold {
    font-weight: 700;
}

.layout {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: url(../img/main-bg.png) center no-repeat;
    background-size: 100% 100%;
    &__header,
    &__footer{
        flex-shrink: 0;
    }
    &__header{
        z-index: 100;
    }
    &__main{
        padding: 120px 0 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        overflow: hidden;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    max-width: 1176px;
    @include rmin(1500) {
        max-width: 1440px;
    }
}

.hidden{
    display: none !important;
}
// Components
@import "components/index";
@import "lib/index";
@import "pages/index.sass";

// Utils
@import "utils/utils";



@media screen and (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
}
@media (inverted-colors) {
    img, video {
        filter: invert(100%);
    }
}

.swiper-container{
    overflow: hidden;
}

