// Typography
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-style: normal;
    margin-top: 0;
    line-height: 1.18;
    &:last-child {
        margin-bottom: 0;
    }
}

h2{
    @include css-lock(24,38,768,1600);

    margin: 0;
}
