.btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: em(8) em(18) em(8) em(8);
    border-radius: 80px;
    text-decoration: none;
    color: currentColor;
    border: none;
    transition: opacity .3s ease-in-out;
    user-select: none;
    &[disabled]{
        opacity: .6;
        pointer-events: none;
    }
    &:not([disabled]):hover{
        opacity: .6;
    }
    &__icon{
        margin: 0 em(15);
        width: 1em;
        height: 1.15em;
    }
    &__lang{
        margin-right: em(15);
    }
    &__lang{
        @include css-lock(42,56,768,1600,width);
        .header__lang &{
            width: 32px;
            @include r(1023){
                margin-right: 0;
                @include css-lock(32,56,450,1024,width);
            }
        }
    }
    &__wrap{
        width: 100%;
        display: flex;
        align-items: center;
        @extend %bg-radial-red;
        border-radius: 80px;
    }
    &.--outline{
		@extend %reset-btn;
		border-radius: 80px;
        padding: em(12) em(18);
        border: 1px solid rgba(255, 255, 255, 0.4);
        .btn__icon{
            margin-left: 0;
            @include css-lock(5,15,450,1023,margin-right);
        }
        @include css-lock(14,18,450,1023);
    }
    &.--primary{
        padding: em(4.5);
        @extend %bg-radial-gold;
        @include css-lock(18,24,768,1600);
        box-shadow: 0px 40px 64px -4px rgba(138, 15, 6, 0.5);
    }
    &.--gold{
        padding: em(4.5);
        @extend %bg-radial-gold;
        @include css-lock(18,24,768,1600);
		.btn__wrap{
			background: #DCB86E;
			box-shadow: inset 0px 0px 8px rgba(63, 1, 0, .5);
		}
    }
}
