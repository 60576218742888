.header {
    position: relative;
    width: 100%;
    &__logo {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        // max-width: fit-content;
        @include css-lock(121,190,768,1600,width);
        overflow: visible;
        &::before{
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 80%;
        }
        img{
            top: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            @include css-lock(200,310,768,1600,width);
            pointer-events: none;
            max-width: none;
        }
    }
    &__container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        @include css-lock(20,40,450,1200,padding);

    }
    &__lang{
        display: flex;
        align-items: center;
        &-item{
            .btn__icon{
                width: 32px;
                height: auto;
            }
            &.--active{
                pointer-events: none;
                color: $color-red;
                @include rmin(1024){
                    background: radial-gradient(circle, rgba(243, 230, 197, 1) 0%, rgba(220, 184, 110, 1) 100%);
                    .btn__icon{
                        margin: 0 em(8) 0 0;
                    }
                }
            }
            @include r(1023){
                &:first-child{
                    @include css-lock(2,10,450,1023,margin-right);
                }
                &.btn{
                    padding: 0;
                }
                span{
                    display: none;
                }
                &:not(.--active){
                    opacity: .4;
                }
            }
        }
        @include r(1023){
            border-radius: 20px;
            padding: 1px;
            background: radial-gradient(circle, rgba(243, 230, 197, 1) 0%, rgba(220, 184, 110, 1) 100%);
        }
    }
}
