%formField {
	width: 100%;
	@include css-lock(15, 30, 768, 1600, border-radius);
	@include css-lock(22, 30, 768, 1600);
	border: none;
	outline: none;
	@include css-lock(16, 25, 768, 1600, padding);
	margin-top: em(14);
	color: $color-red;
	text-align: center;

	&::-webkit-input-placeholder {
		color: $color-red;
		opacity: .5;
	}

	/* Firefox 19+ */
	&::-moz-placeholder {
		color: $color-red;
		opacity: .5;
	}

	/* Firefox 18- */
	&:-moz-placeholder {
		color: $color-red;
		opacity: .5;
	}

	&:-ms-input-placeholder {
		color: $color-red;
		opacity: .5;
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		-webkit-text-fill-color: $color-red;
		-webkit-box-shadow: 0 0 0px 40rem #ffff inset;
	}
}



/*general slider status*/
.flow-slider {
	max-width: 99vw;

	&__slide {}

	.step {
		&_1 {
			@include css-lock(910, 1150, 345, 1600, min-height);

			/*demo*/
			&:has(.demo) {
				min-height: 5000px !important;
			}

			.load-img,
			.loadAndResize__textField,
			.form__submit {
				transition: opacity .23s;
			}

			&.is-sending {

				.load-img,
				.loadAndResize__textField,
				.form__submit {
					opacity: .4;
					pointer-events: none;
				}

				.crop-sending-window {
					display: flex;

					&__status .inner {
						transform: scaleX(.25);
					}
				}
			}

			@for $i from 1 through 3 {
				&[data-sending="#{$i}"] {
					.status-sending {
						display: block;

						span:not(:nth-child(#{$i})) {
							display: none;
						}
					}

					.crop-sending-window__status .inner {
						transform: scaleX(#{.33 + (0.33 * $i)});
					}
				}
			}

		}

		&_2 {
			@include r(767) {
				padding: 0 em(15);
			}

			.pack__item {
				.crop-sending-window {
					&__status .inner {
						transform: scaleX(0);
						transition-duration: 5s;
						transition-timing-function: cubic-bezier(0, 0.49, 0.41, 1);
					}
				}

				&.is-sending {
					.crop-sending-window {
						display: flex;
						// &__status .inner{
						//     transform: scaleX(1);
						// }
					}

					&_animate {
						.crop-sending-window .inner {
							transform: scaleX(1);
						}
					}
				}
				&.is-invisible{
					pointer-events: none;
				}
			}
		}

		&_3 {
			text-align: center;

		}
	}

}

/* general form style*/
.form {
	&__row {
		width: 100%;
		max-width: mIn(350px, 95%);
		margin: 0 auto;
	}

	&__input {
		@extend %formField;

		@include placeholder {
			@include css-lock(15, 18, 768, 1600);
		}

		.kiosk-page & {
			@include placeholder {
				@include css-lock(22, 30, 768, 1600);
			}
		}
	}

	&__submit {
		color: $color-main;
		margin: em(30) auto 0;
		width: 100%;

		.btn__wrap {
			justify-content: space-between;
			padding: em(19.5) em(15) em(19.5) em(25);
		}
	}

}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

.placeholder {
	margin-top: 0.7777777778em;
	display: block;
	width: 100%;
	position: relative;
	display: flex;

	&:hover {
		cursor: pointer;
	}

	input {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;

		&:checked~.pseudo {
			&::after {
				opacity: 1;
			}
		}
	}

	.pseudo {
		flex-shrink: 0;
		margin-top: 0.2em;
		margin-right: .7em;
		width: 1.1em;
		height: 1.1em;
		border: 2px solid #DCB86E;
		background-color: #8A0F06;
		border-radius: 2px;
		position: relative;

		&::after {
			content: '';
			width: .7em;
			height: .7em;
			opacity: 0;
			@extend %bg-radial-gold;
			@include abs-center();
		}
	}

	.text {
		flex: 1;
		font-size: 14px;
		line-height: 22px;

		p {
			margin-top: 0;
		}

		a {
			color: currentColor;

			&:hover {
				text-decoration: none;
			}
		}
	}
}









/*step 1 load img*/
.loadAndResize {
	max-width: mIn(350px, 95%);
	margin: 0 auto;
}

.load-img {
	position: relative;
	line-height: 1.4;

	&__description {
		@extend %formField;
		display: block;
		position: relative;
		background-color: #fff;
		color: $color-red;
	}

	&__placeholder {
		// @include css-lock(18,24,768,1600);
		@include css-lock(15, 18, 768, 1600);
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: .5;

		.icon {
			margin-right: .4em;
			width: 1.4em;
			height: 1.4em;
		}
	}

	&__name {
		white-space: nowrap;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 1.5em;

	}

	button {
		@extend %reset-btn;
	}

	&__input {
		opacity: 0;
		position: absolute;
		clip-path: polygon(0 0, 0 0, 0 0, 0 0);
		width: 0;
		height: 0;
	}

	.load-img__remove {
		position: absolute;
		right: 0.7em;
		top: 50%;
		transform: translateY(-50%);
		color: $color-red;
		@include css-lock(18, 24, 768, 1600);

		&::before {
			content: '';
			position: absolute;
			width: 2em;
			height: 2em;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%)
		}
	}

	&__error {
		@include css-lock(18, 24, 768, 1600);
	}

	&.is-empty {

		.load-img__error,
		.load-img__name,
		.load-img__remove {
			display: none;
		}
	}

	&.is-error {
		.load-img__description {
			outline: 3px dashed $color-red;
		}

		.load-img__placeholder,
		.load-img__name,
		.load-img__remove {
			display: none;
		}
	}

	&.is-active {

		.load-img__placeholder,
		.load-img__error {
			display: none;
		}
	}
}

/*step 1 crop img*/
.crop-img {
	padding: 5px;
	max-height: 0;
	overflow: hidden;
	width: 100%;
	transition: .35s ease-in-out;
	position: relative;
	border-radius: 8px;
	overscroll-behavior: none;
	user-select: none;
	touch-action: none;
	&::before {
		content: '';
		position: absolute;
		pointer-events: none;
		border-radius: inherit;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: radial-gradient(circle, rgba(243, 230, 197, 1) 0%, rgba(220, 184, 110, 1) 100%);
		opacity: 0;
		transition: .35s ease-in-out;
	}

	&__wrapper {
		position: relative;
		font-size: 0;
		z-index: 1;
		border-radius: 6px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		&::before {
			content: '';
			display: block;
			// padding-bottom: 96%;
			padding-bottom: 120%;
		}

	}

	&__helper {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 2;
		pointer-events: none;
		background-image: url('../img/border.png');
		background-size: 100% 100%;
		background-position: center;
	}

	&__inner {
		position: absolute;

		width: 100%;
		display: flex;


	}

	img {
		display: none;
		width: 100%;
		height: auto;
		position: relative;
	}

	&.is-active {
		margin-top: 25px;
		max-height: 500px;

		&::before {
			opacity: 1;
		}

		img {
			display: block;
		}
	}
}

/*from 1 to 2 step*/
.crop-sending-window {
	@extend %overlay;
	z-index: 10;
	display: none;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;
	align-content: center;
	text-align: center;

	&::before {
		content: '';
		@extend %overlay;
		background-color: rgba($color-red, .8);
		z-index: 0;
	}

	&__text {
		color: white;
		font-size: 16px;
		font-weight: 300;
		line-height: 23px;
		display: block;
		position: relative;

		p {
			margin-top: 0;
			margin-bottom: 0.3em;
		}
	}

	&__status {
		display: block;
		height: 4px;
		width: 250px;
		max-width: 90%;
		margin: 15px auto;
		background-color: rgba(220, 184, 110, .5);
		overflow: hidden;
		position: relative;
		z-index: 1;
		border-radius: 4px;

		.inner {
			position: absolute;
			width: 100%;
			border-radius: inherit;
			top: 0;
			left: 0;
			height: 100%;
			transform-origin: 0 50%;
			transition: 2s;
			background-color: $color-gold;
		}
	}

	.status-sending {
		display: none;
	}
}

.AmarilloText {
	font-size: 0;
	font-family: "Amarillo", "ZonaPro-Bold";
}

.demo-row {
	margin: 2em auto;
	width: 1070px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto auto;
	gap: 10px;
	border: 1px solid rgba(white, .2);

	.demo-col {
		border: 1px solid wheat;

		&:first-child {
			grid-column: 1 / span 1;
			grid-row: 1 / span 1;
		}

		&:nth-child(2) {
			grid-column: 2 / span 1;
			grid-row: 1 / span 1;
		}

		&:nth-child(3) {
			grid-column: 3 / span 1;
			grid-row: 1 / span 1;
		}

		&:last-child {
			grid-column: 1 / span 3;
			grid-row: 2 / span 1;
		}
	}

	canvas {
		max-width: 100%;
	}
}
