.kiosk-page{
	.container{
		max-width: 100%;
	}
	.more-btn{
		margin: 1em auto;
		.btn__wrap{
			min-height: 2.4em;
			min-width: 10em;
			justify-content: center;
		}
	}
	// .intro{
	// 	min-height: 900px;
	// }
}
.form{
	&_operator{
		margin: 0 auto 2em;
		max-width: min(350px,95%);
	}
}
.operator-list{
	@extend %reset-list;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1.5em;
	width: 96vw;
	&::after{
		content: '';

	}
	&__btn{
		@extend %reset-btn;
	}
	&__image{
		border-radius: .3em;
	}
	&_userImages{
		.operator-list__item{
			@include aspect-ratio(333,399,true);
			overflow: hidden;
			border: 5px solid #DCB86E;
			background-color: #8A0F06;
			border-radius: 15px;
			.crop-sending-window {
				&__status .inner {
					transform: scaleX(0);
					transition-duration: 5s;
					transition-timing-function: cubic-bezier(0, 0.49, 0.41, 1);
				}
			}

			&.is-sending {
				.crop-sending-window {
					display: flex;
				}

				&_animate {
					.crop-sending-window .inner {
						transform: scaleX(1);
					}
				}
			}
			img{
				@extend %overlay-full;
				border-radius: 0;
			}
			&:hover{
				cursor: pointer;
			}
		}
	}
	.pack__item-image-wrapper{
		&:hover{
			cursor: pointer;
		}
	}
}


.kiosk-modal{
	background-color: #F3E6C5;
    box-shadow: 0px 32px 64px rgb(0 0 0 / 25%);
    border-radius: 16px;
    max-width: mIn(900px, 90vw);
    padding: 57px 20px 20px;
    position: relative;
	transition: background-color .23s ease;
	.mfp-image-holder & .mfp-close{
		position: absolute;
		width: 40px;
		height: 40px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		right: 10px;
    	top: 10px;
		color: $color-red;
	}
	.mfp-img{
		padding: 0;
		margin: 0;
		position: absolute;
		width: 100%;
		height: 149%;
		top: -24.5%;
		left: 0;
		&__wrapper{
			margin-bottom: 20px;
			overflow: hidden;
			border-radius: 8px;
			width: mIn(850px, 85vw);
			@include aspect-ratio(317,132,true);
		}
	}
	&__btns{
		display: flex;
		justify-content: center;
		.btn{
			font-size: 18px !important;
			&__wrap{
				min-height: 3em;
				min-width: 10em;
				justify-content: center;
			}
			&__icon{
				margin: 0 0.4em 0 0;
			}
			& + .btn{
				margin-left: 1em;
			}
		}
	}
}
